import { Component } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { CustomValidators } from 'ng2-validation';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as $ from 'jquery';
import { MediaMatcher } from '@angular/cdk/layout';
import { NgbModal, ModalDismissReasons, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AccountsService } from '../../../shared/Services/accounts/accounts.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent 
{
    closeResult: string | undefined;
    public config: PerfectScrollbarConfigInterface = {};
    displayusername:any;
    modalReference: any;
    private modalRef: NgbModalRef | undefined;
    notificationcount: any;
    isEmpty = true;
  getDismissReason: any;
  displayrolename:any;
  sidnavcss:any;
  sidcontencss:any;
    constructor(private route: ActivatedRoute, private router: Router, public dialog: MatDialog, private modalService: NgbModal, private apiService: AccountsService)
    {
        this.displayusername = sessionStorage['UserName'];
       this.displayrolename= sessionStorage['RoleName'];
       // this.loadpendingkotdetails();
    }

  
    
    // This is for Notifications
    notifications: Object[] = [{
      round: 'round-danger',
      icon: 'ti-link',
      title: 'Launch Admin',
      subject: 'Just see the my new admin!',
      time: '9:30 AM'
    }, {
      round: 'round-success',
      icon: 'ti-calendar',
      title: 'Event today',
      subject: 'Just a reminder that you have event',
      time: '9:10 AM'
    }, {
      round: 'round-info',
      icon: 'ti-settings',
      title: 'Settings',
      subject: 'You can customize this template as you want',
      time: '9:08 AM'
    }, {
      round: 'round-primary',
      icon: 'ti-user',
      title: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }];

    // This is for Mymessages
    mymessages: Object[] = [{
      useravatar: 'assets/images/users/1.jpg',
      status: 'online',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:30 AM'
    }, {
      useravatar: 'assets/images/users/2.jpg',
      status: 'busy',
      from: 'Sonu Nigam',
      subject: 'I have sung a song! See you at',
      time: '9:10 AM'
    }, {
      useravatar: 'assets/images/users/2.jpg',
      status: 'away',
      from: 'Arijit Sinh',
      subject: 'I am a singer!',
      time: '9:08 AM'
    }, {
      useravatar: 'assets/images/users/4.jpg',
      status: 'offline',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM'
        }];

    Objpendingkotsave: { restId: any; deviceId: string; username: any; userid: any; } | undefined;
    resppendingkotDatasave: unknown;
    pendingkotlist: any;
    pendingkotlistlength: any;
    notificationskotlist: string | any[] | undefined;

    // loadpendingkotdetails() {

    //     this.Objpendingkotsave =
    //         {
    //             "restId": sessionStorage['restId'],
    //             "deviceId": "",
    //             "username": sessionStorage['username'],
    //             "userid": sessionStorage['userid']


    //         };
    //     this.resppendingkotDatasave = [];
    //     this.apiService
    //         .getNotifications(this.Objpendingkotsave)
    //         .then((data) => {
    //             console.log(data);
    //             this.resppendingkotDatasave = data;

    //             //this.notificationskotlist = this.resppendingkotDatasave.notifications;
    //             //this.pendingkotlistlength = this.notificationskotlist.length;
    //             //this.notificationcount = this.resppendingkotDatasave.unReadCount;
    //         })
    //         .then(() => {
    //             // if (this.resppendingkotDatasave.length === 0) {
    //             //     this.isEmpty = true;
    //             // } else {
    //             //     this.isEmpty = false;
    //             // }
    //         })
    //         .catch(err => console.log(err))
    // }
  addnew() {
    this.route.params.subscribe((params: any) => {
        this.router.navigate(['/Pghouses/changepassword']);
    });
    }
    notificationss()
    {
        this.notificationcount = sessionStorage['notifications'];
        this.route.params.subscribe((params: any) => {
            this.router.navigate(['/accounts/notifications']);
        });
    }
    
  

    logout()
    {
      sessionStorage.clear();
      this.route.params.subscribe((params: any) => {
            this.router.navigate(['/signin']);
        });
    }

    manuclick()
    {
      // this.sidnavcss = document.querySelector("#snav");
      // this.sidnavcss.style.cssText = "box-shadow: none; visibility: hidden;"
      // this.sidcontencss = document.querySelector("#sidcontenid");
      // this.sidcontencss.style.cssText = "margin-left: 0px; margin-right: 0px;"
      const listItems = document.querySelectorAll('.main-wrapper');
      if(listItems[0].attributes[0].value=="main-wrapper")
      {
        listItems[0].attributes[0].value="main-wrapper slide-nav";
        //listItems[0].setAttribute ('style', 'display: block;');
      }
      else if(listItems[0].attributes[0].value=="main-wrapper slide-nav"){
        listItems[0].attributes[0].value="main-wrapper";
      }
      //   this.sidnavcss = document.querySelector("#snav");
      // const newLocal = this.newMethod();
      // const style = newLocal;
      //   style.cssText = "box-shadow: none; visibility: hidden;"
      //   this.sidcontencss = document.querySelector("#sidcontenid");
      //   this.sidcontencss.style.cssText = "margin-left: 0px; margin-right: 0px;"
    }

  private newMethod() {
    const newLocal = this.sidnavcss;
    const style = newLocal;
    return style;
  }
}
