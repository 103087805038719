<!-- <div class="sidebar" id="sidebar"> -->
  <div class="sidebar-inner slimscroll">
      <div id="sidebar-menu" class="sidebar-menu">

        <!-- <ul class="nav">
           
            <li *ngFor="let item of menuData">
              
              <a [routerLink]="item.href">{{item.label}}</a>
              <ul *ngIf="item.children">
                <li *ngFor="let submenu of item.children">
                  <a [routerLink]="submenu.href">{{submenu.label}}</a>
                  <ul *ngIf="submenu.children">
                    <li *ngFor="let submenu2 of submenu.children">
                      <a [routerLink]="submenu2.href">{{submenu2.label}}</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
        </ul> -->
        
          <!-- <ul *ngFor="let menuitem of menuItems.getMenuitem()"> -->
           

                  <ul *ngFor="let menuitem of memuslists">

                      <li *ngIf="menuitem.type !== 'sub' && menuitem.ParentMenuId===0" id="liids{{menuitem.Menuid}}">
                         
                          <a href="{{menuitem.Menuurl}}" (click)="onClick('notsub',menuitem.Menuid)"><i class="{{ menuitem.Menucssclass }}"></i> <span>{{ menuitem.MenuName }}</span></a>

                      </li>
                      <li *ngIf="menuitem.type === 'sub'" class="submenu" >
                          <a href="{{menuitem.Menuurl}}" (click)="onClick('sub',menuitem.Menuid)">
                              <i class="{{ menuitem.Menucssclass }}"></i> <span> {{menuitem.MenuName }} </span>
                              <span class="menu-arrow"></span>
                          </a>
                          <ul style="display: none" *ngFor="let childitem of menuitem.childmenus" id="ulid{{menuitem.Menuid}}">
                            <!-- <li id="liids{{childitem.Menuid}}"><a href="{{childitem.Menuurl}}" *ngIf="menuitem.Menuid===childitem.ParentMenuId" (click)="onClickchildmenu(childitem.Menuid)"><i class="{{ childitem.Menucssclass }}"></i> <span>{{childitem.MenuName }}</span></a></li> -->

                              <li id="liids{{childitem.Menuid}}"><a href="/PGHOME{{childitem.Menuurl}}" *ngIf="menuitem.Menuid===childitem.ParentMenuId" (click)="onClickchildmenu(childitem.Menuid)"><i class="{{ childitem.Menucssclass }}"></i> <span>{{childitem.MenuName }}</span></a></li>

                          </ul>



                      </li>

                  </ul>
                

    

      </div>
  </div>
<!-- </div> -->