<body class="login-bg">
  <div class="main-wrapper login-body">
      <div class="login-wrapper">
          <div class="container">
              <div class="loginbox">
                  <div class="login-right">
                      <div class="login-right-wrap">
                          <div class="text-center pb-3">
                              <img src="assets/img/pcsadthidilogo.png" class="img-fluid" alt="" />
                          </div>
                          <h1 class="py-3">Login</h1>
                          <form [formGroup]="form" (ngSubmit)="onSubmit()">
                         
                              <div class="form-group">
                                  <input class="form-control" type="text" placeholder="User"
                                  formControlName="username" [ngClass]="{ 'is-invalid': submitted && f['username'].errors }" />
                              
                                  <div *ngIf="submitted && f['username'].errors" class="invalid-feedback">
                                    <div *ngIf="f['username'].errors['required']">Username is required</div>
                                    <div *ngIf="f['username'].errors['minlength']">
                                      Username must be at least 6 characters
                                    </div>
                                    <div *ngIf="f['username'].errors['maxlength']">
                                      Username must not exceed 20 characters
                                    </div>
                                  </div>
                                </div>
                              <div class="form-group icon-div" >
                                  <input class="form-control"  [type]="password" formControlName="password"
                                  [ngClass]="{ 'is-invalid': submitted && f['password'].errors }"
                                   placeholder="Password" />
                                   <i class="fa fa-eye" aria-hidden="true" (click)="onClick()" *ngIf="!show"></i>
                                   <i class="fa fa-eye-slash" aria-hidden="true"  (click)="onClick()" *ngIf="show"></i>

                                   <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
                                    <div *ngIf="f['password'].errors['required']">Password is required</div>
                                    <div *ngIf="f['password'].errors['minlength']">
                                      Password must be at least 6 characters
                                    </div>
                                    <div *ngIf="f['password'].errors['maxlength']">
                                      Username must not exceed 40 characters
                                    </div>
                                  </div>
                                
                                </div>
                              <div class="form-group">
                                  <button class="btn btn-primary btn-block" type="submit">
                                      Login
                                  </button>
                              </div>
                              
                         
<!-- 
                          <div class="text-center forgotpass">
                              <a href="forgot-password.html">Forgot Password?</a>
                          </div> -->
                          </form>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>

  
</body>