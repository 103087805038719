import { NgModule } from '@angular/core';

import { MenuItems } from './menu-items/menu-items';
import { webconfig } from './Services/webconfig';
import { AccordionAnchorDirective, AccordionLinkDirective, AccordionDirective } from './accordion';
import { LoginService } from './Services/login/login.service';
import { MenusService } from './Services/menus/menus.service';
import { AccountsService } from './Services/accounts/accounts.service';
import { FilterdataPipe } from './Services/filters/filterdata.pipe';
import {UsersService} from './Services/users/users.service';
import {RoomsmasterService} from './Services/roomsmaster/roomsmaster.service';
import {RolesService} from './Services/roles/roles.service';
import {FloorsService} from './Services/floors/floors.service';
import {FacilitiesService} from './Services/facilities/facilities.service';
import {BedsService} from './Services/beds/beds.service';
import {FoodsService} from './Services/Foods/foods.service';
import {PaymentsService} from './Services/payments/payments.service'
import {ReportsService} from './Services/reports/reports.service';

import {ComplaintsService} from './Services/complaints/complaints.service';


@NgModule({
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
 
    FilterdataPipe,
       
  ],
  exports: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective
   ],
    providers: [MenuItems, webconfig, LoginService, MenusService,  AccountsService,UsersService,
      RoomsmasterService,RolesService,FloorsService,FacilitiesService,BedsService,FoodsService,PaymentsService,ReportsService,ComplaintsService ]
})
export class SharedModule { }
