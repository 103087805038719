<!-- <div class="main-container" > 

    <mat-toolbar color="primary" class="topbar telative" style="background: #fb0527;height: 45px;">

        <div class="navbar-header" style="width: 150px;">


            <a class="navbar-brand">

                <span fxShow="false" fxShow.gt-xs>

                    <img src="../../../assets/images/malaweb/laalsanewlogo.png" class="light-logo" alt="homepage" style="width: 100px;">

                </span>
            </a>


        </div>


        <button mat-icon-button (click)="snav.toggle()" value="sidebarclosed">
            <mat-icon>menu</mat-icon>
        </button>



       
        <span fxShow="false" fxShow.gt-xs></span>

        <span fxShow="false" fxShow.gt-xs style="margin-left: 5%;">

            


        </span>

        <span fxShow="false" fxShow.gt-xs style="margin-left: 5%;">

           


        </span>


        <span fxShow="false" fxShow.gt-xs style="margin-left: 2%;">

          

        </span>
        <span fxShow="false" fxShow.gt-xs style="margin-left: 2%;">

           

        </span>
        <span fxShow="false" fxShow.gt-xs style="margin-left: 2%;">

          

        </span>

        <span fxFlex></span>

        <app-header></app-header>

    </mat-toolbar>
  
    <mat-sidenav-container>
     
        <mat-sidenav #snav id="snav"  [mode]="mobileQuery.matches ? 'side' : 'over'" fixedTopGap="0" [opened]="mobileQuery.matches" [disableClose]="mobileQuery.matches" (open)="sidebarOpened = true" (close)="sidebarOpened = false" [perfectScrollbar]="config" >
            
                <app-sidebar></app-sidebar>
               
            
        </mat-sidenav>
     
        <div>
          <router-outlet><app-spinner></app-spinner></router-outlet>
        </div>
    
    </mat-sidenav-container>
</div> -->


<div class="main-wrapper">
    <div class="header">
        <app-header></app-header>
    </div>
    <div class="sidebar" id="sidebar">
        <app-sidebar></app-sidebar>
    </div>
    <!-- <div class="page-wrapper"> -->
        <router-outlet><app-spinner></app-spinner></router-outlet>
    <!-- </div> -->
</div>
