import * as $ from 'jquery';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
//import { DemoMaterialModule } from './demo-material-module';
//import { AppRoutingModule } from './app-routing.module';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';
import { SiginComponent } from './sigin/sigin.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { AppHeaderComponent } from './layouts/full/header/header.component';
import { AppSidebarComponent } from './layouts/full/sidebar/sidebar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DemoMaterialModule } from './demo-material-module';

import { ToastrModule } from 'ngx-toastr';
import { ApiService } from './providers/api-service/api.service';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { SharedModule } from './shared/shared.module';
import { SpinnerComponent } from './shared/spinner.component';
//import { MaterialTimeControlModule } from './material-time-control.module';
import {CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { Ng2SearchPipeModule } from 'ng2-search-filter';
//import { AngularFireMessagingModule } from '@angular/fire/messaging';
//import { AngularFireDatabaseModule } from '@angular/fire/database';
//import { AngularFireAuthModule } from '@angular/fire/auth';
//import { AngularFireModule } from '@angular/fire';
import { MessagingService } from './shared/messaging.service';
//import { environment } from '../environments/environment';
import { AsyncPipe } from '../../node_modules/@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NgChartsModule } from 'ng2-charts';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true,
};

@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    AppHeaderComponent,
    SpinnerComponent,
    AppBlankComponent,
    AppSidebarComponent,
    SiginComponent,
    DashboardComponent
   
    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    FormsModule,
    ReactiveFormsModule ,
    FlexLayoutModule,
    HttpClientModule,
      PerfectScrollbarModule,
      NgChartsModule,
    SharedModule,
    ToastrModule.forRoot(), // ToastrModule added
    NgbModule,
    RouterModule.forRoot(AppRoutes),
    Ng2SearchPipeModule
     
  ],
  providers: [
    //ApiService,
    
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }, {
    provide: LocationStrategy,
    useClass: HashLocationStrategy
      },
      //MessagingService,
      AsyncPipe
  ],
    bootstrap: [AppComponent],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
    ],
})
export class AppModule { }
