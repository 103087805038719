import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl,FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../shared/Services/login/login.service';
import { MenuItems } from '../shared/menu-items/menu-items';

import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-sigin',
  templateUrl: './sigin.component.html',
  styleUrls: ['./sigin.component.css']
})
export class SiginComponent implements OnInit {

  //public form!: FormGroup;
  isEmpty = true;
  //normalshow;
  //notnormalshow;
 // public  OPENTBL;
 form: FormGroup = new FormGroup({
  username: new FormControl(''),
  password: new FormControl(''),
 
});
submitted = false;

password:any;

show = false;
memuslist:any;
  memuslists:any;
  childmenulist:any;
  userid:any;
  menuData:any
  

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private router: Router, private apiService: LoginService, private menulog: MenuItems,private toastr: ToastrService,private location: LocationStrategy)

  { 

    history.pushState(null, '', window.location.href);  
    this.location.onPopState(() => {
      history.pushState(null, '', window.location.href);
    });  
  }

ngOnInit() {
   // this.normalshow = true;
   // this.notnormalshow = false;

   this.password = 'password';

 
  this.form = this.fb.group({
    username: [null, Validators.compose([Validators.required])],
    password: [null, Validators.compose([Validators.required])]
  });
}


onClick() {
  if (this.password === 'password') 
  {
    this.password = 'text';
    this.show = true;
  } else {
    this.password = 'password';
    this.show = false;
  }
}


Getmenusfun()
  {
    this.loginData = [];
    this.memuslist = [];
    this.memuslists = [];
    this.childmenulist=[];
    
  this.apiService
    .displaymenus(sessionStorage['Userid'])
    .then((data) => {
      this.loginData = data;
     
      this.memuslist=this.loginData;
      sessionStorage['menulist']=this.memuslist;
      sessionStorage.memuslist = JSON.stringify(this.loginData[0]);
     
      
      this.router.navigate(['/dashboards/dashboard1']);
    
      
      })
    
   
  }

get f(): { [key: string]: AbstractControl } {
  return this.form.controls;
}
loginObj: any;
loginData:any;
loginDatas:any;
onSubmit(): void {
  this.submitted = true;

  if (this.form.invalid==false) 
  {
    this.loginObj =
      {
      "UserName": this.form.value.username,
      "Password": this.form.value.password
      }
      this.loginData = [];
      this.loginDatas = [];
    this.apiService
      .login(this.loginObj)
      .then((data) => {
        this.loginData = data;
        this.loginDatas=this.loginData[0];
        if (this.loginDatas.length>0)
        {
          
          this.toastr.success("Login Successfully");
          sessionStorage['Userid'] = this.loginDatas[0].Userid;
          sessionStorage['Roleid'] = this.loginDatas[0].Roleid;
          sessionStorage['RoleName'] = this.loginDatas[0].RoleName;
          sessionStorage['ClientId'] = this.loginDatas[0].ClientId;
          sessionStorage['UserName'] = this.loginDatas[0].UserName;
          sessionStorage['Emailid'] = this.loginDatas[0].Emailid;
          sessionStorage["Typeofuser"]=this.loginDatas[0].Typeofuser;

          this.Getmenusfun();

         
        }
        else{
          this.toastr.error("Username and Password do not match");
        }
        })
      .then(() => {
        if (this.loginData.length === 0) {
          this.isEmpty = true;
        } else {
          this.isEmpty = false;
        }
      })
      .catch(err =>
      {
        
        this.toastr.error(err.error.message);
      })
    //return;
  }

 // console.log(JSON.stringify(this.form.value, null, 2));
}

//loginerrormessage: any;
// login(): void {
//   this.submitted = true;

 

//   this.route.params.subscribe(params => {
//     this.router.navigate(['/dashboards/dashboard1']);
    
//   })

//     console.log(JSON.stringify(this.form.value, null, 2));
// }

}  
