import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { webconfig } from '../webconfig';

@Injectable({
  providedIn: 'root'
})
export class UsersService 
{
  ENDPOINTS: any;
  private _session: any;
  constructor(public conn: webconfig, public http: HttpClient) 
  { 

    this.ENDPOINTS = conn.getconnectionjava();
  }
  getAllUsers(id:any) 
  {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.ENDPOINTS + '/user/users/' + id)
        .subscribe((data) => {
          if (data != undefined) {
            return resolve(data);
          }
          return reject(data);
        }, err => {
          reject(err);
        })
    })
  }

  getAllroles() 
  {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.ENDPOINTS + '/api/roles')
        .subscribe((data) => {
          if (data != undefined) {
            return resolve(data);
          }
          return reject(data);
        }, err => {
          reject(err);
        })
    })
  }

  Addusers(body:any) {
  
    return new Promise((resolve, reject) => {
      this.http
        .post(this.ENDPOINTS + '/user/saveusers', body)
        .subscribe((data) => {
          if (data != undefined) {
            return resolve(data);
          }
          return reject(data);
        }, err => {
          reject(err);
        })
    })
  }

  getbyuserid(id:any) 
  {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.ENDPOINTS + '/user/userbyid/' + id)
        .subscribe((data) => {
          if (data != undefined) {
            return resolve(data);
          }
          return reject(data);
        }, err => {
          reject(err);
        })
    })
  }

  Updateuser(body:any) {
  
    return new Promise((resolve, reject) => {
      this.http
        .post(this.ENDPOINTS + '/user/updateusers', body)
        .subscribe((data) => {
          if (data != undefined) {
            return resolve(data);
          }
          return reject(data);
        }, err => {
          reject(err);
        })
    })
  }

  
  changepassword(body:any) {
  
    return new Promise((resolve, reject) => {
      this.http
        .post(this.ENDPOINTS + '/user/changepassword', body)
        .subscribe((data) => {
          if (data != undefined) {
            return resolve(data);
          }
          return reject(data);
        }, err => {
          reject(err);
        })
    })
  }

  
  deleteuser(id:any) 
  {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.ENDPOINTS + '/user/deleteusers/' + id)
        .subscribe((data) => {
          if (data != undefined) {
            return resolve(data);
          }
          return reject(data);
        }, err => {
          reject(err);
        })
    })
  }



}
export interface ResponseObj {
  data: any[],
  status: string,
  errorMessage: string,
}
