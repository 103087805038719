import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { webconfig } from '../webconfig';

@Injectable({
  providedIn: 'root'
})
export class RoomsmasterService
 {

  ENDPOINTS: any;
  private _session: any;
  constructor(public conn: webconfig, public http: HttpClient) 
  { 

    this.ENDPOINTS = conn.getconnectionjava();
  }
  getAllRooms(id:any) 
  {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.ENDPOINTS + '/api/rooms/' +id)
        .subscribe((data) => {
          if (data != undefined) {
            return resolve(data);
          }
          return reject(data);
        }, err => {
          reject(err);
        })
    })
  }

  getAllFloors(id:any) 
  {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.ENDPOINTS + '/api/floors/' +id)
        .subscribe((data) => {
          if (data != undefined) {
            return resolve(data);
          }
          return reject(data);
        }, err => {
          reject(err);
        })
    })
  }

  getAllRoomtypes(id:any) 
  {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.ENDPOINTS + '/api/roomstypes/'+id)
        .subscribe((data) => {
          if (data != undefined) {
            return resolve(data);
          }
          return reject(data);
        }, err => {
          reject(err);
        })
    })
  }
  //getallRoomstypes

  getallRoomstypes(id:any) 
  {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.ENDPOINTS + '/api/getallRoomstypes/'+id)
        .subscribe((data) => {
          if (data != undefined) {
            return resolve(data);
          }
          return reject(data);
        }, err => {
          reject(err);
        })
    })
  }

  getAllRoomtypesbycategoryid(id:any,categoryid:any) 
  {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.ENDPOINTS + '/api/roomstypes/'+id +'/' + categoryid)
        .subscribe((data) => {
          if (data != undefined) {
            return resolve(data);
          }
          return reject(data);
        }, err => {
          reject(err);
        })
    })
  }

  getAllRoomcategory(id:any) 
  {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.ENDPOINTS + '/api/roomscategories/'+id)
        .subscribe((data) => {
          if (data != undefined) {
            return resolve(data);
          }
          return reject(data);
        }, err => {
          reject(err);
        })
    })
  }

  AddRooms(body:any) {
  
    return new Promise((resolve, reject) => {
      this.http
        .post(this.ENDPOINTS + '/api/saverooms', body)
        .subscribe((data) => {
          if (data != undefined) {
            return resolve(data);
          }
          return reject(data);
        }, err => {
          reject(err);
        })
    })
  }

  Addbeds(body:any) {
  
    return new Promise((resolve, reject) => {
      this.http
        .post(this.ENDPOINTS + '/api/addbeds', body)
        .subscribe((data) => {
          if (data != undefined) {
            return resolve(data);
          }
          return reject(data);
        }, err => {
          reject(err);
        })
    })
  }

  getbyroomid(id:any) 
  {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.ENDPOINTS + '/api/roomsbyid/' + id)
        .subscribe((data) => {
          if (data != undefined) {
            return resolve(data);
          }
          return reject(data);
        }, err => {
          reject(err);
        })
    })
  }

  Updaterooms(body:any) {
  
    return new Promise((resolve, reject) => {
      this.http
        .post(this.ENDPOINTS + '/api/updaterooms', body)
        .subscribe((data) => {
          if (data != undefined) {
            return resolve(data);
          }
          return reject(data);
        }, err => {
          reject(err);
        })
    })
  }

  
  deleterooms(id:any) 
  {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.ENDPOINTS + '/api/deleterooms/' + id)
        .subscribe((data) => {
          if (data != undefined) {
            return resolve(data);
          }
          return reject(data);
        }, err => {
          reject(err);
        })
    })
  }



}
export interface ResponseObj {
  data: any[],
  status: string,
  errorMessage: string,
}

