import { Injectable } from '@angular/core';




export interface BadgeItem {
  type: string;
  value: string;
}
export interface Saperator {
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  saperator?: Saperator[];
  children?: ChildrenItems[];
}

const MENUITEMS = [
  // {
  //  state: 'dashboards',
  //  name: 'Dashboards',
  //  type: 'sub',
  //  icon: 'av_timer',
  //  children: [
  //    // {state: 'dashboard1', name: 'Dashboard 1'},
  //    // {state: 'dashboard2', name: 'Dashboard 2'},
  //  ]
  //},
  {
    state: '/#/dashboards/dashboard1',
    name: 'Dashboard',
    type: 'Main',
    icon: 'fe fe-desktop',
    children: []
    },
    {
        state: '/#/Pghouses/roomenquiry',
        name: 'Room Enquiry',
        type: 'Main',
        icon: 'fe fe-home',
        children: []
    },
    {
        state: '/#/Pghouses/roomallowcation',
        name: 'Room Allocation',
        type: 'Main',
        icon: 'fe fe-home',
        children: []
    },
    {
        state: '/#/Pghouses/roomavailable',
        name: 'Room Available',
        type: 'Main',
        icon: 'fe fe-home',
        children: []
    },
    {
        state: '/#/Pghouses/guestdetails',
        name: 'Guest Details',
        type: 'Main',
        icon: 'fe fe-users',
        children: []
    },
    {
        state: '/#/Pghouses/payments',
        name: 'Payments',
        type: 'Main',
        icon: 'fe fe-money',
        children: []
    },
    {
        state: '/#/Pghouses/foodmanagements',
        name: 'Food Management',
        type: 'sub',
        icon: 'fe fe-cutlery',
        children: [
        { state: 'list-company', name: 'Company' },
        { state: 'list-branch', name: 'Branch' }
    ]
    },
    // {
    //     state: '/kots/displaykots',
    //     name: "Staff Management",
    //     type: 'Main',
    //     icon: 'fe fe-bell',
    //     children: []
    // },
    {
        state: '',
        name: 'Reports',
        type: 'Main',
        icon: 'fe fe-file',
        children: []
    }
    
   
  // {
  //  state: 'dinein',
  //  name: 'Dinein',
  //  type: 'sub',
  //  icon: 'person',
  //  children: [
  //    { state: 'tables', name: 'Dinein' }
  //  ]
  //},


  //{
  //  state : 'logout',
  //  name : 'Logout',
  //  type : 'sub',
  //  icon : 'exit_to_app',
  //  children:[
  //    {state: 'list-epg', name: 'EPG Guide'}
  //        ]
  //}

  
 
  

];


const MENUITEMSS = [
    // {
    //  state: 'dashboards',
    //  name: 'Dashboards',
    //  type: 'sub',
    //  icon: 'av_timer',
    //  children: [
    //    // {state: 'dashboard1', name: 'Dashboard 1'},
    //    // {state: 'dashboard2', name: 'Dashboard 2'},
    //  ]
    //},

    {
        state: '/dashboards/dashboard1',
        name: 'Dashboard',
        type: 'Main',
        icon: 'fe fe-desktop',
        children: []
        },
        {
            state: '/roomallowcation',
            name: 'Room Allocation',
            type: 'Main',
            icon: 'fe fe-home',
            children: []
        },
        {
            state: '/reports/sessionreport',
            name: 'Room Available',
            type: 'Main',
            icon: 'fe fe-home',
            children: []
        },
        {
            state: '/invoices/displayinvoices',
            name: 'Guest Details',
            type: 'Main',
            icon: 'fe fe-users',
            children: []
        },
        {
            state: '/offers/displayoffers',
            name: 'Payments',
            type: 'Main',
            icon: 'fe fe-money',
            children: []
        },
        {
            state: '/inventorymanage/displayinventory',
            name: 'Food Management',
            type: 'Main',
            icon: 'fe fe-cutlery',
            children: []
        },
        {
            state: '/kots/displaykots',
            name: "Staff Management",
            type: 'Main',
            icon: 'fe fe-bell',
            children: []
        },
        {
            state: '/splittable/displaysplittable',
            name: 'Reports',
            type: 'Main',
            icon: 'fe fe-file',
            children: []
        }
      



];

@Injectable()

export class MenuItems
{
    MENUITEMSSS = [];
    permessionarray = [];
    private _name: string | undefined;
    private nonchargebalename: string | undefined;
    public opentable: string | undefined;
    public REPRINTKOT: any;
    valueobjpermission: never[] | undefined;
    nonchargeble: any;
  pipe: any;
    getMenuitem(): Menu[]
    {
    return MENUITEMS;
    }

    getMenuitems() : Menu[]
    {
       
        //this.MENUITEMSSS = MENUITEMS;
        return this.MENUITEMSSS;
    }

    public getnonchargename() {
        const nonchargebalename = this.nonchargebalename;
        return nonchargebalename;
    }
    public getopentable() {
        return this.opentable;
    }

    public getname(){
        return  this._name;
    }

    public setname(value: string) {
        this._name = value;
    }

    public permission()
    {
        let objpermission: string | any[] | undefined= [];
         let valueobjpermission = objpermission;
        let permessionarray = this.valueobjpermission;
        for (var i = 0; i < objpermission.length; i++) {
            if (objpermission[i].name == 'OPENTBL' && objpermission[i].has_scopes == true) {
                this.opentable = objpermission[i].name;
            }
            if (objpermission[i].name == 'OPENNCTBL' && objpermission[i].has_scopes == true) {
                this.nonchargebalename = "OPENNCTBL";
            }
            if (objpermission[i].name == 'REPRINTKOT' && objpermission[i].has_scopes == true)
            
            {
                this.REPRINTKOT = objpermission[i].name;
            }

        }
    }

}

