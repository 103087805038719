import { ChangeDetectorRef, Component, NgZone, OnDestroy, ViewChild, HostListener, Directive, AfterViewInit,OnInit } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MediaMatcher } from '@angular/cdk/layout';
import { MenuItems } from '../../../shared/menu-items/menu-items';
import { MenusService } from '../../../shared/Services/menus/menus.service';
import { map } from 'rxjs/operators';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: []
})
export class AppSidebarComponent implements OnInit {
  public config: PerfectScrollbarConfigInterface = {};
  mobileQuery: MediaQueryList;
    dineInpermission: any;
    firesalepermission: any;
    sidnavcss:any;
    sidcontencss:any;
  private _mobileQueryListener: () => void;
  loginObj: any;
  loginData:any;
  memuslist:any;
  memuslists:any;
  childmenulist:any;
  userid:any;
  menuData:any
    constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, private apiService: MenusService, public menuItems: MenuItems)
    {
       this.menuData=[];
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.apiService.getObjectMenu().subscribe(res=>{
      this.menuData=res;
    })
    
    // this.memuslist=sessionStorage['menulist'];
    // this.memuslist=;
    
    
    // for(var i=0;i< parsedJSON.length;i++)
    //   {
        
    //   }
    this. Getmenusfun();
   

  }

  Getmenusfun()
  {
    this.loginData = [];
    this.memuslist = [];
    this.memuslists = [];
    this.childmenulist=[];
    this.userid=sessionStorage['Userid'];
    this.memuslist = JSON.parse(sessionStorage.memuslist);

    for(var i=0;i<this.memuslist.length;i++)
      {
        if(this.memuslist[i].type=='sub')
        {
        this.childmenulist=this.getChildren(this.memuslist,this.memuslist[i].Menuid);
        }
     
        if(this.memuslist[i].ParentMenuId==0)
        {
        
          this.memuslists.push({
          "Menuid":this.memuslist[i].Menuid,
          "Menuurl":'/PGHOME'+this.memuslist[i].Menuurl,
          //"Menuurl":this.memuslist[i].Menuurl,
          "Menucssclass":this.memuslist[i].Menucssclass,
          "MenuName":this.memuslist[i].MenuName,
          "ParentMenuId":this.memuslist[i].ParentMenuId,
          "type":this.memuslist[i].type,
          "childmenus":this.childmenulist

        })
      }

      }
    
    
   
  }

  Getmenusfuns()
  {
    this.loginData = [];
    this.memuslist = [];
    this.memuslists = [];
    this.childmenulist=[];
    this.userid=sessionStorage['Userid'];
  this.apiService
    .displaymenus(this.userid)
    .then((data) => {
      this.loginData = data;
      // this.memuslist.push({
      //   'country': 'UK',
      //   'children': [
      //     {
      //       "name": "Douglas  Pace"
      //     },
      //     {
      //       "name": "Mcleod  Mueller"
      //     },
      //   ]
      // })
      this.memuslist=this.loginData[0];
      sessionStorage['menulist']=this.memuslist;
      
      for(var i=0;i<this.memuslist.length;i++)
      {
        if(this.memuslist[i].type=='sub')
        {
        this.childmenulist=this.getChildren(this.memuslist,this.memuslist[i].Menuid);
        }
     
        if(this.memuslist[i].ParentMenuId==0)
        {
        
          this.memuslists.push({
          "Menuid":this.memuslist[i].Menuid,
          "Menuurl":this.memuslist[i].Menuurl,
          "Menucssclass":this.memuslist[i].Menucssclass,
          "MenuName":this.memuslist[i].MenuName,
          "ParentMenuId":this.memuslist[i].ParentMenuId,
          "type":this.memuslist[i].type,
          "childmenus":this.childmenulist

        })
      }

      }
    

      
      
      
      })
    
   
  }

  

  filter(itemList:any,id:number) {
    this.childmenulist=[];
    this.childmenulist=this.getChildren(this.memuslist,id);
    return this.childmenulist;
    // let result: any[] = [];
    // //your filter logic here
    
    // return itemList().pipe(
    //   map((menu: any[]) =>
    //     menu.filter(x=>!x.menuId).map(x => ({ ...x, children: this.getChildren(menu,x.id) }))
    //   )
    // );
    // return itemList.filter(x=>x.Menuid==id).map((x:any)=>{
    //   return ({
    //     Menuid:x.Menuid,
    //     MenuName:x.MenuName,
    //     Menuurl:x.Menuurl,
      

    // })}
    // )
    
  }
  getObjectMenu(memuslist:any) {
    return memuslist.pipe(
      map((menu: any[]) =>
        menu.filter(x=>!x.menuId).map(x => ({ ...x, children: this.getChildren(menu,x.id) }))
      )
    );
  }
  getChildren(menu:any[],id:number): any{
    return menu.filter(x=>x.ParentMenuId==id).map((x:any)=>{
      return ({
      ...x,
      children:this.getChildren(menu,x.id)

    })}
    )
 }
  elementClicked :any;
  
  elem:any;
  lielem:any;

  elementClickeds :any;
  
  elems:any;
  lielems:any;
  elemsclassname:any;
  listsideItems:any;

  onClick(objval:string,id:any) {
    // this.childmenulist=[];
    // this.childmenulist=this.getChildren(this.memuslist,id);
   // this.childmenu(this.memuslist); sidebar-overlay opened
   // const listmainItems = document.querySelectorAll('.main-wrapper');
  //  if(listmainItems[0].attributes[0].value=="main-wrapper")
  //  {
  //   listmainItems[0].attributes[0].value="main-wrapper slide-nav";
  //    //listItems[0].setAttribute ('style', 'display: block;');
  //  }
  //  else if(listmainItems[0].attributes[0].value=="main-wrapper slide-nav"){
  //   listmainItems[0].attributes[0].value="main-wrapper";
  //  }
// if(listmainItems.length>0)
// {
//   if(listmainItems[0].attributes[0].value=="main-wrapper slide-nav"){
//     listmainItems[0].attributes[0].value="main-wrapper";
//    }
// }
  
if(objval!='sub')
{
  this.listsideItems = document.querySelectorAll('.sidebar-overlay');
   if(this.listsideItems[0].attributes[0].value=="sidebar-overlay opened")
   {
    this.listsideItems[0].attributes[0].value="sidebar-overlay";
    this.listsideItems[0].parentElement.parentElement.className="";
    this.listsideItems[0].parentElement.children[0].childNodes[0].childNodes[1].childNodes[0].className="main-wrapper"
     //listItems[0].setAttribute ('style', 'display: block;');
   }

}

   
  


    this.elem = document.getElementById("ulid" + id);
    const listItems = document.querySelectorAll('.submenu ul');
    if(listItems.length>0)
    {
      for (let i = 0; i <= listItems.length - 1;i++) 
    {
      if(listItems[i].attributes[0].value=="display: none;" && objval=="sub" && this.elem.id==listItems[i].id)
      {
        listItems[i].setAttribute ('style', 'display: block;');
        //i++;
      }
      else if (listItems[i].attributes[0].value!="display: none;")
      {
        listItems[i].setAttribute ('style', 'display: none;');
        //i++;
      }
      
    }

   // this.onClicklifun(id);


    

    }
    
    
    for(var j=0;j<this.memuslist.length;j++)
    {
      this.elems= document.getElementById("liids" + id);
       this.elemsclassname=document.getElementById("liids" + this.memuslist[j].Menuid);
      
      // this.elems.className="";
   
      if(this.memuslist[j].Menuid==id)
      {
        this.elemsclassname.className="ng-star-inserted active";
        
      // this.elems.className="ng-star-inserted active";
        //break;
      }
      else
      {
        if(this.elemsclassname!=null)
        {
          if(this.elemsclassname.className=="ng-star-inserted active"){
            this.elemsclassname.className="ng-star-inserted";
            }
        }
         
        
       // this.elemsclassname.className="ng-star-inserted";
    
       // this.elemsclassname.className="ng-star-inserted";
        
        // if(this.elemsclassname.className=="active")
        // {
         // this.elemsclassname.className="ng-star-inserted";
        // }

      }

     
    }



  }

  elemschildclassname:any;
  onClickchildmenu(id:any)
  {
    this.listsideItems = document.querySelectorAll('.sidebar-overlay');
   if(this.listsideItems[0].attributes[0].value=="sidebar-overlay opened")
   {
    this.listsideItems[0].attributes[0].value="sidebar-overlay";
    this.listsideItems[0].parentElement.parentElement.className="";
    this.listsideItems[0].parentElement.children[0].childNodes[0].childNodes[1].childNodes[0].className="main-wrapper"
     //listItems[0].setAttribute ('style', 'display: block;');
   }

    for(var k=0;k<this.memuslist.length;k++)
    {
      
       this.elemschildclassname=document.getElementById("liids" + this.memuslist[k].Menuid);
      
      // this.elems.className="";
   
      if(this.memuslist[k].Menuid==id)
      {
        this.elemschildclassname.className="ng-star-inserted active";
        
      
      }
      else
      {
        if(this.elemschildclassname!=null)
        {
          if(this.elemschildclassname.className=="ng-star-inserted active"){
            this.elemschildclassname.className="ng-star-inserted";
            }
        }
         
        
       

      }

     
    }


  }
  
  onClicklifun(id:any)
  {
    this.lielem = document.getElementById("liids" + id);
    
    const listItemss = document.querySelectorAll('li');
    if(listItemss.length>0)
    {
      for(let i = 0; i <= listItemss.length - 1;i++)
      {
      if(listItemss[i].id==this.lielem.id)
      {
        listItemss[i].className= 'active';
      }else
      {
        listItemss[i].className = '';
      }

      }
       

    }

  }
  
  
  ngOnInit(): void {
   
    this.mobileQuery.removeListener(this._mobileQueryListener);

   
   
  }

    manuclick()
    {
      this.sidnavcss = document.querySelector("#snav");
      this.sidnavcss.style.cssText = "box-shadow: none; visibility: hidden;"
      this.sidcontencss = document.querySelector("#sidcontenid");
      this.sidcontencss.style.cssText = "margin-left: 0px; margin-right: 0px;"
      //   this.sidnavcss = document.querySelector("#snav");
      // const newLocal = this.newMethod();
      // const style = newLocal;
      //   style.cssText = "box-shadow: none; visibility: hidden;"
      //   this.sidcontencss = document.querySelector("#sidcontenid");
      //   this.sidcontencss.style.cssText = "margin-left: 0px; margin-right: 0px;"
    }

  private newMethod() {
    const newLocal = this.sidnavcss;
    const style = newLocal;
    return style;
  }
}
