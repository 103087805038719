import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { webconfig } from '../webconfig';
import { data } from 'jquery';
@Injectable({
  providedIn: 'root'
})

export class LoginService
{
  ENDPOINTS: any;
  private _session: any;
  //httpOptions: { headers: any; } | undefined;
  constructor(public conn: webconfig, public http: HttpClient)
  {
    this.ENDPOINTS = conn.getconnectionjava();
  }
  login(body: any) {
    // return new Promise((resolve, reject) => {
    //   this.http
    //     .post(this.ENDPOINTS + '/user/userlogin', body)
    //     .subscribe((data) => {
    //       if (data != undefined) {
    //         return resolve(data);
    //       }
    //       return reject(data);
    //     }, err => {
    //       reject(err);
    //     })
    // })
    return new Promise((resolve, reject) => {
      // this.httpOptions = {
      //   headers: new HttpHeaders({ 'Content-Type': 'application/json',
      //   'Access-Control-Allow-Origin':'*'})
      // };
     // , this.httpOptions
     let headers = new HttpHeaders()
     //headers=headers.append('content-type','application/json')
     headers=headers.append('Access-Control-Allow-Origin', '*')
     if (!headers.has('content-type')) {
       headers=headers.append('content-type','application/json')
     }
      this.http
        .post(this.ENDPOINTS + '/user/userlogin', body, {'headers':headers})
        .subscribe((data) => {
          if (data != undefined) {
            return resolve(data);
          }
          return reject(data);
        }, err => {
          reject(err);
        })
    })
  }

  displaymenus(body:any) {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.ENDPOINTS + '/api/menususer/' +body)
        .subscribe((data) => {
          if (data != undefined) {
            return resolve(data);
          }
          return reject(data);
        }, err => {
          reject(err);
        })
    })
  }
 
}
export interface ResponseObj {
  data: any[],
  status: string,
  errorMessage: string,
}
