import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { webconfig } from '../webconfig';

@Injectable()
export class AccountsService {
    ENDPOINTS: any;
    private _session: any;
    constructor(public conn: webconfig, public http: HttpClient)
    {
        this.ENDPOINTS = conn.getconnectionjava();
    }

    getNotifications(body: any) {
        return new Promise((resolve, reject) => {
            this.http
                .post(this.ENDPOINTS + '/order/getNotifications', body)
                .subscribe((data) => {
                    if (data != undefined) {
                        return resolve(data);
                    }
                    return reject(data);
                }, err => {
                    reject(err);
                })
        })
    }

    readNotification(body: any) {
        return new Promise((resolve, reject) => {
            this.http
                .post(this.ENDPOINTS + '/order/readNotification', body)
                .subscribe((data) => {
                    if (data != undefined) {
                        return resolve(data);
                    }
                    return reject(data);
                }, err => {
                    reject(err);
                })
        })
    }

    changePassword(body: any) {
        return new Promise((resolve, reject) => {
            this.http
                .post(this.ENDPOINTS + '/user/changePassword', body)
                .subscribe((data) => {
                    if (data != undefined) {
                        return resolve(data);
                    }
                    return reject(data);
                }, err => {
                    reject(err);
                })
        })
    }
}
export interface ResponseObj {
    data: any[],
    status: string,
    errorMessage: string,
}
