//import * as $ from 'jquery';
import { MediaMatcher } from '@angular/cdk/layout';
import { Router, ActivatedRoute } from '@angular/router';
import { ChangeDetectorRef, Component, NgZone, OnDestroy, ViewChild, HostListener, Directive, AfterViewInit } from '@angular/core';
import { MenuItems } from '../../shared/menu-items/menu-items';
import { AppHeaderComponent } from './header/header.component';
import { AppSidebarComponent } from './sidebar/sidebar.component';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { MenusService } from '../../shared/Services/menus/menus.service';

import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
/** @title Responsive sidenav */
@Component({
  selector: 'app-full-layout',
  templateUrl: 'full.component.html',
  styleUrls: [],
})
export class FullComponent implements OnDestroy, AfterViewInit {
  mobileQuery: MediaQueryList;
  dir = 'ltr';
  green: boolean | undefined;
  blue: boolean | undefined;
  dark: boolean = false;
  minisidebar: boolean = false;
  boxed: boolean = false;
  danger: boolean = false;
  showHide: boolean = false;
  sidebarOpened: any;
  globaloutlates;
    subrestlength;
    titlename;

  public config: PerfectScrollbarConfigInterface = {};
  private _mobileQueryListener: () => void;
  isEmpty = true;
    constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, private route: ActivatedRoute, private router: Router, private apiService: MenusService, public menuItems: MenuItems)
    {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    
    this.globaloutlates = false;
    if (sessionStorage['outlates'] == 'outlates') {
      this.loadmenus();
    }
    else {
      this.subrestlength = 0;
        }

        this.titlename = this.menuItems.getname();
    
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
  ngAfterViewInit() {

      var container = document.querySelector("#snav");
     // This is for the topbar search
     (<any>$('.srh-btn, .cl-srh-btn')).on('click', function () {
            (<any>$('.app-search')).toggle(200);
        });
     // This is for the megamenu

  }

  // Mini sidebar


  Objsave: { restId: any; username: any; userid: any; permitedRestaurants: any; orderType: string; vegInd: boolean; } | undefined;
  respDatasave: unknown;
  
  subrestarray: any;
  loadmenus() {

   
  }


}
