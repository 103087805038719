import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { webconfig } from '../webconfig';
import { of, forkJoin } from 'rxjs';
import { delay, map, take } from 'rxjs/operators';

@Injectable()
export class MenusService {
  ENDPOINTS: any;
  private _session: any;
  constructor(public conn: webconfig, public http: HttpClient)
  {
    this.ENDPOINTS = conn.getconnectionjava();
  }


  getAllmenus() 
  {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.ENDPOINTS + '/api/menus')
        .subscribe((data) => {
          if (data != undefined) {
            return resolve(data);
          }
          return reject(data);
        }, err => {
          reject(err);
        })
    })
  }



  AddMenus(body:any) {
  
    return new Promise((resolve, reject) => {
      this.http
        .post(this.ENDPOINTS + '/api/savemenus', body)
        .subscribe((data) => {
          if (data != undefined) {
            return resolve(data);
          }
          return reject(data);
        }, err => {
          reject(err);
        })
    })
  }

  getbymenuid(id:any) 
  {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.ENDPOINTS + '/api/menusbyid/' + id)
        .subscribe((data) => {
          if (data != undefined) {
            return resolve(data);
          }
          return reject(data);
        }, err => {
          reject(err);
        })
    })
  }

  Updatemenus(body:any) {
  
    return new Promise((resolve, reject) => {
      this.http
        .post(this.ENDPOINTS + '/api/updatemenus', body)
        .subscribe((data) => {
          if (data != undefined) {
            return resolve(data);
          }
          return reject(data);
        }, err => {
          reject(err);
        })
    })
  }

  
  deletemenus(id:any) 
  {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.ENDPOINTS + '/api/deletemenus/' + id)
        .subscribe((data) => {
          if (data != undefined) {
            return resolve(data);
          }
          return reject(data);
        }, err => {
          reject(err);
        })
    })
  }

  displaymenus(body:any) {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.ENDPOINTS + '/api/menususer/' +body)
        .subscribe((data) => {
          if (data != undefined) {
            return resolve(data);
          }
          return reject(data);
        }, err => {
          reject(err);
        })
    })
  }

  getMenuItemDetails(body: any) {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.ENDPOINTS + '/restaurant/getMenuItemDetails', body)
        .subscribe((data) => {
          if (data != undefined) {
            return resolve(data);
          }
          return reject(data);
        }, err => {
          reject(err);
        })
    })
  }
  



    searchMenu(body: any) {
        return new Promise((resolve, reject) => {
            this.http
                .post(this.ENDPOINTS + '/microsite/searchMenu', body)
                .subscribe((data: any) => {
                    if (data != undefined) {
                        return resolve(data);
                    }
                    return reject(data.errorMessage);
                }, err => {
                    reject(err);
                })
        })
    }

    voidItem(body: any) {
        return new Promise((resolve, reject) => {
            this.http
                .post(this.ENDPOINTS + '/order/voidBill', body)
                .subscribe((data: any) => {
                    if (data != undefined) {
                        return resolve(data);
                    }
                    return reject(data.errorMessage);
                }, err => {
                    reject(err);
                })
        })
    }


    getMenuItemDetailsearch(body: any) {
        return new Promise((resolve, reject) => {
            this.http
                .post(this.ENDPOINTS + '/restaurant/getMenuItemDetails', body)
                .subscribe((data: any) => {
                    if (data != undefined) {
                        return resolve(data);
                    }
                    return reject(data.errorMessage);
                }, err => {
                    reject(err);
                })
        })
    }


    getMenu() {
      //return this.httpClient.get("myApi/menu");
      //I simulate using of and delay
      return of([
        { id: 1, href: '', label: 'Home' },
        { id: 2, href: '', label: 'Servicios' },
        { id: 3, href: '', label: 'About' },
        { id: 4, href: '', label: 'Contact' }
      ]);
    }
  
    getSubMenu() {
      //return this.httpClient.get("myApi/submenu");
      //I simulate uing of and delay
      return of([
        { id: 1, href: '', label: 'Submenu1', menuId: 2 },
        { id: 2, href: '', label: 'Submenu2', menuId: 2 },
        { id: 3, href: '', label: 'Submenu3', menuId: 2 },
        { id: 4, href: '', label: 'Submenu4', menuId: 2 },
        { id: 5, href: '', label: 'Submenu1', menuId: 3 },
        { id: 6, href: '', label: 'Submenu2', menuId: 3 },
        { id: 7, href: '', label: 'Submenu3', menuId: 3 },
        { id: 8, href: '', label: 'Submenu4', menuId: 3 }
      ]);
    }
    getAllMenu() {
      return of([
        { id: 1, href: '', label: 'Home' },
        { id: 2, href: '', label: 'Servicios' },
        { id: 3, href: '', label: 'About' },
        { id: 4, href: '', label: 'Contact' },
        { id: 5, href: '', label: 'Submenu1', menuId: 2 },
        { id: 6, href: '', label: 'Submenu2', menuId: 2 },
        { id: 7, href: '', label: 'Submenu3', menuId: 2 },
        { id: 8, href: '', label: 'Submenu4', menuId: 2 },
        { id: 9, href: '', label: 'Submenu1', menuId: 3 },
        { id: 10, href: '', label: 'Submenu2', menuId: 3 },
        { id: 11, href: '', label: 'Submenu3', menuId: 3 },
        { id: 12, href: '', label: 'Submenu4', menuId: 3 },
        { id: 13, href: '', label: 'Submenu1', menuId: 8 },
        { id: 14, href: '', label: 'Submenu2', menuId: 8 },
        { id: 15, href: '', label: 'Submenu3', menuId: 8 },
        { id: 16, href: '', label: 'Submenu4', menuId: 8 }
      ]);
    }
    getObjectMenu() {
      return forkJoin([this.getMenu(), this.getSubMenu()]).pipe(
        map(([menu, submenu]: [any[], any[]]) => {
          return menu.map((x: any) => ({
            ...x,
            children: submenu.filter(s => s.menuId == x.id)
          }));
        })
      );
    }
    getObjectMenu2() {
      return this.getAllMenu().pipe(
        map((menu: any[]) =>
          menu.filter(x=>!x.menuId).map(x => ({ ...x, children: this.getChildren(menu,x.id) }))
        )
      );
    }
    getChildren(menu:any[],id:number): any{
       return menu.filter(x=>x.menuId==id).map((x:any)=>{
         return ({
         ...x,
         children:this.getChildren(menu,x.id)
  
       })}
       )
    }

     

}

export interface ResponseObj {
  data: any[],
  status: string,
  errorMessage: string,
}
