import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';



//const ENDPOINTS = "http://localhost:8090";//local
//const ENDPOINTSjava = "http://localhost:8090";

const ENDPOINTS = "https://api.athidhidevo.com";//local
const ENDPOINTSjava = "https://api.athidhidevo.com";
//const ENDPOINTS = "http://103.194.8.175:3000";//local
//const ENDPOINTSjava = "http://103.194.8.175:3000";//local


export class webconfig
{
  getconnection() {
    return ENDPOINTS;

  }
  getconnectionjava() {

    return ENDPOINTSjava;

  }
}
