import { Routes, RouterModule } from '@angular/router';
import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { SiginComponent } from './sigin/sigin.component';


export const AppRoutes: Routes = [
    { path: 'signin', component: SiginComponent },
    //{ path: '/layouts/full', component: FullComponent },
    { path: 'dashboard', component: FullComponent },
    
  {
  path: '',
    component: FullComponent,
    children: [
      {
      path: '',
      redirectTo: '/signin',
      pathMatch: 'full'
    },
    { 
      path: 'dashboards',
      loadChildren: () => import('./dashboards/dashboards.module').then(x => x.DashboardsModule)
   },
   {
    path: 'Pghouses',
    //loadChildren: './Pghouses/Pghouses.module#PghousesModule'
    loadChildren: () => import('./pghouses/pghouses.module').then(mod => mod.PghousesModule),
  }
    
     
     
      
    ]
}];
