import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { webconfig } from '../webconfig';

@Injectable({
  providedIn: 'root'
})
export class FoodsService {

  ENDPOINTS: any;
  private _session: any;
  constructor(public conn: webconfig, public http: HttpClient) 
  { 

    this.ENDPOINTS = conn.getconnectionjava();
  }
  getAllfoods(id:any) 
  {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.ENDPOINTS + '/api/foods/' + id)
        .subscribe((data) => {
          if (data != undefined) {
            return resolve(data);
          }
          return reject(data);
        }, err => {
          reject(err);
        })
    })
  }



  Addfoods(body:any) {
  
    return new Promise((resolve, reject) => {
      this.http
        .post(this.ENDPOINTS + '/api/savefoods', body)
        .subscribe((data) => {
          if (data != undefined) {
            return resolve(data);
          }
          return reject(data);
        }, err => {
          reject(err);
        })
    })
  }

  getbyfoodid(id:any) 
  {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.ENDPOINTS + '/api/foodsbyid/' + id)
        .subscribe((data) => {
          if (data != undefined) {
            return resolve(data);
          }
          return reject(data);
        }, err => {
          reject(err);
        })
    })
  }

  Updatefoods(body:any) {
  
    return new Promise((resolve, reject) => {
      this.http
        .post(this.ENDPOINTS + '/api/updatefoods', body)
        .subscribe((data) => {
          if (data != undefined) {
            return resolve(data);
          }
          return reject(data);
        }, err => {
          reject(err);
        })
    })
  }

  
  deletefoods(id:any) 
  {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.ENDPOINTS + '/api/deletefoods/' + id)
        .subscribe((data) => {
          if (data != undefined) {
            return resolve(data);
          }
          return reject(data);
        }, err => {
          reject(err);
        })
    })
  }



}
export interface ResponseObj {
  data: any[],
  status: string,
  errorMessage: string,
}


