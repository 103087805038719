import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { webconfig } from '../webconfig';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  ENDPOINTS: any;
  private _session: any;
  constructor(public conn: webconfig, public http: HttpClient) 
  { 

    this.ENDPOINTS = conn.getconnectionjava();
  }
  getAllFloors(id:any) 
  {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.ENDPOINTS + '/api/floors/' +id)
        .subscribe((data) => {
          if (data != undefined) {
            return resolve(data);
          }
          return reject(data);
        }, err => {
          reject(err);
        })
    })
  }

  getroomcategories(id: any) {
    return new Promise((resolve, reject) => {
        this.http
            .get(this.ENDPOINTS + '/api/roomscategories/' + id)
            .subscribe((data) => {
                if (data != undefined) {
                    return resolve(data);
                }
                return reject(data);
            }, err => {
                reject(err);
            })
    })
}

getallsharingrooms(id: any) {
  return new Promise((resolve, reject) => {
      this.http
          .get(this.ENDPOINTS + '/api/getallRoomstypes/' + id)
          .subscribe((data) => {
              if (data != undefined) {
                  return resolve(data);
              }
              return reject(data);
          }, err => {
              reject(err);
          })
  })
}

getroomtypes(id: any, RoomcategoryId:any) {
  return new Promise((resolve, reject) => {
      this.http
          .get(this.ENDPOINTS + '/api/roomstypes/' + id + '/' + RoomcategoryId)
          .subscribe((data) => {
              if (data != undefined) {
                  return resolve(data);
              }
              return reject(data);
          }, err => {
              reject(err);
          })
  })
}

Roomsfloors(body:any) {
  
  return new Promise((resolve, reject) => {
    this.http
      .post(this.ENDPOINTS + '/api/Roomsharingfloors', body)
      .subscribe((data) => {
        if (data != undefined) {
          return resolve(data);
        }
        return reject(data);
      }, err => {
        reject(err);
      })
  })
}

roombyfloorid(body:any) {
  
  return new Promise((resolve, reject) => {
    this.http
      .post(this.ENDPOINTS + '/api/roombyfloorid', body)
      .subscribe((data) => {
        if (data != undefined) {
          return resolve(data);
        }
        return reject(data);
      }, err => {
        reject(err);
      })
  })
}

getallrooms(id: any) {
  return new Promise((resolve, reject) => {
      this.http
          .get(this.ENDPOINTS + '/api/rooms/' + id)
          .subscribe((data) => {
              if (data != undefined) {
                  return resolve(data);
              }
              return reject(data);
          }, err => {
              reject(err);
          })
  })
}
 





  getAllRoomsreport(id:any,roomtypes:any,Roomsharing:any,Floorid:any, Roomid:any) 
  {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.ENDPOINTS + '/api/getroomsstatusreport/'+id + '/' + roomtypes + '/' +Roomsharing +'/'+Floorid + '/' +Roomid)
        .subscribe((data) => {
          if (data != undefined) {
            return resolve(data);
          }
          return reject(data);
        }, err => {
          reject(err);
        })
    })
  } 


  getAllBedsreport(id:any,roomtypes:any,Roomsharing:any,Floorid:any, Roomid:any) 
  {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.ENDPOINTS + '/api/getbedsdetailsreport/'+id + '/' + roomtypes + '/' +Roomsharing +'/'+Floorid + '/' +Roomid)
        .subscribe((data) => {
          if (data != undefined) {
            return resolve(data);
          }
          return reject(data);
        }, err => {
          reject(err);
        })
    })
  } 
 
  getAllguestsreport(id:any,roomtypes:any,Roomsharing:any,Floorid:any, Roomid:any) 
  {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.ENDPOINTS + '/api/getGuestsReports/'+id + '/' + roomtypes + '/' +Roomsharing +'/'+Floorid + '/' +Roomid)
        .subscribe((data) => {
          if (data != undefined) {
            return resolve(data);
          }
          return reject(data);
        }, err => {
          reject(err);
        })
    })
  } 

  getAllfoodsreport(id:any,FoodDate:any) 
  {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.ENDPOINTS + '/api/getfoodreports/'+id +"/"+FoodDate)
        .subscribe((data) => {
          if (data != undefined) {
            return resolve(data);
          }
          return reject(data);
        }, err => {
          reject(err);
        })
    })
  } 
  


}
export interface ResponseObj {
  data: any[],
  status: string,
  errorMessage: string,
}

