import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { webconfig } from '../webconfig';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  ENDPOINTS: any;
  private _session: any;
  constructor(public conn: webconfig, public http: HttpClient)
   { 

    this.ENDPOINTS = conn.getconnectionjava();
   }

    getAllguests(id:any) 
   {
     return new Promise((resolve, reject) => {
       this.http
         .get(this.ENDPOINTS + '/api/totalguests/'+id)
         .subscribe((data) => {
           if (data != undefined) {
             return resolve(data);
           }
           return reject(data);
         }, err => {
           reject(err);
         })
     })
   }


  //  getwhatsapp() 
  //  {
  //   let headers: HttpHeaders = new HttpHeaders();
  //   headers = headers.append('Access-Control-Allow-Origin', '*');
  //   headers = headers.append("Access-Control-Allow-Credentials", "true");
  //   headers = headers.append("Access-Control-Allow-Methods", "GET, POST, PATCH, PUT, DELETE, OPTIONS, HEAD");
  //   headers = headers.append("Access-Control-Allow-Headers", "Origin, Accept, X-Requested-With, Content-Type, "
  //           + "Access-Control-Request-Method, Access-Control-Request-Headers, Authorization");
    
  //   // headers = headers.append('Content-Type', 'application/json');
  //   // headers = headers.append('Authorization', 'Bearer ' + sessionStorage.tokenid);
  //    return new Promise((resolve, reject) => {
  //      this.http
  //        .get(this.ENDPOINTS + '/example', { headers })
  //        .subscribe((data) => {
  //          if (data != undefined) {
  //            return resolve(data);
  //          }
  //          return reject(data);
  //        }, err => {
  //          reject(err);
  //        })
  //    })
  //  }
   getbyguestid(id:any) 
   {
     return new Promise((resolve, reject) => {
       this.http
         .get(this.ENDPOINTS + '/api/guestsbyid/' + id)
         .subscribe((data) => {
           if (data != undefined) {
             return resolve(data);
           }
           return reject(data);
         }, err => {
           reject(err);
         })
     })
   }
 
   Addpayments(body:any) {
  
    return new Promise((resolve, reject) => {
      this.http
        .post(this.ENDPOINTS + '/api/savepayments', body)
        .subscribe((data) => {
          if (data != undefined) {
            return resolve(data);
          }
          return reject(data);
        }, err => {
          reject(err);
        })
    })
  }
   getAllpaymentguests(body:any) 
   {
     return new Promise((resolve, reject) => {
       this.http
         .post(this.ENDPOINTS + '/api/paymentsguests/', body)
         .subscribe((data) => {
           if (data != undefined) {
             return resolve(data);
           }
           return reject(data);
         }, err => {
           reject(err);
         })
     })
   }

   getpendingpaymentguests(id:any) 
   {
     return new Promise((resolve, reject) => {
       this.http
         .get(this.ENDPOINTS + '/api/getpendingpaymentsGuests/'+ id)
         .subscribe((data) => {
           if (data != undefined) {
             return resolve(data);
           }
           return reject(data);
         }, err => {
           reject(err);
         })
     })
   }


}
export interface ResponseObj {
  data: any[],
  status: string,
  errorMessage: string,
}