import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { webconfig } from '../webconfig';

@Injectable({
  providedIn: 'root'
})
export class ComplaintsService {

  ENDPOINTS: any;
  private _session: any;
  constructor(public conn: webconfig, public http: HttpClient)
   { 

    this.ENDPOINTS = conn.getconnectionjava();
   }

   getComplaints(id:any,name:any) 
   {
     return new Promise((resolve, reject) => {
       this.http
         .get(this.ENDPOINTS + '/api/getComplaints/'+id +'/' + name)
         .subscribe((data) => {
           if (data != undefined) {
             return resolve(data);
           }
           return reject(data);
         }, err => {
           reject(err);
         })
     })
   }


 
   complaintsbyid(id:any) 
   {
     return new Promise((resolve, reject) => {
       this.http
         .get(this.ENDPOINTS + '/api/complaintsbyid/' + id)
         .subscribe((data) => {
           if (data != undefined) {
             return resolve(data);
           }
           return reject(data);
         }, err => {
           reject(err);
         })
     })
   }

   
   getcomplaintsbyguestid(id:any,name:any) 
   {
     return new Promise((resolve, reject) => {
       this.http
         .get(this.ENDPOINTS + '/api/getcomplaintsbyguestid/' + id +'/'+name)
         .subscribe((data) => {
           if (data != undefined) {
             return resolve(data);
           }
           return reject(data);
         }, err => {
           reject(err);
         })
     })
   }
 
   AddComplaints(body:any) {
  
    return new Promise((resolve, reject) => {
      this.http
        .post(this.ENDPOINTS + '/api/saveComplaints', body)
        .subscribe((data) => {
          if (data != undefined) {
            return resolve(data);
          }
          return reject(data);
        }, err => {
          reject(err);
        })
    })
  }
   
  updateComplaints(body:any) {
  
    return new Promise((resolve, reject) => {
      this.http
        .post(this.ENDPOINTS + '/api/updateComplaints', body)
        .subscribe((data) => {
          if (data != undefined) {
            return resolve(data);
          }
          return reject(data);
        }, err => {
          reject(err);
        })
    })
  }
   


}
export interface ResponseObj {
  data: any[],
  status: string,
  errorMessage: string,
}