<div class="header">
    <div class="header-left">
        <a href="dashboard.html" class="logo">
            <img src="assets/img/pcsadthidilogo.png" alt="Logo" />
        </a>
        <a href="index.html" class="logo logo-small">
            <img src="assets/img/pcsadthidi.png" alt="Logo" width="30" height="30" />
        </a>
    </div>

    <a href="javascript:void(0);" id="toggle_btn" >
        <i class="fe fe-text-align-left"></i>
    </a>
    <!-- <div class="top-nav-search">
        <form>
            <input type="text" class="form-control" placeholder="Search here" />
            <button class="btn" type="submit">
                <i class="fa fa-search"></i>
            </button>
        </form>
    </div> -->

    <a class="mobile_btn" id="mobile_btn" (click)="manuclick()">
        <i class="fa fa-bars"></i>
    </a>

    <ul class="nav user-menu">
        <li class="nav-item dropdown noti-dropdown">
            <a href="#" class="dropdown-toggle nav-link" data-bs-toggle="dropdown">
                <i class="fa fa-bell"></i> <span class="badge badge-pill">3</span>
            </a>
            <div class="dropdown-menu notifications">
                <div class="topnav-dropdown-header">
                    <span class="notification-title">Notifications</span>
                    <a href="javascript:void(0)" class="clear-noti"> Clear All </a>
                </div>
                <div class="noti-content">
                    <ul class="notification-list">
                        <li class="notification-message">
                            <a href="#">
                                <div class="media d-flex">
                                    <!-- <span class="avatar avatar-sm flex-shrink-0">
                                        <img class="avatar-img rounded-circle" alt="User Image"
                                            src="assets/img/profiles/avatar-02.jpg" />
                                    </span> -->
                                    <div class="media-body flex-grow-1">
                                        <p class="noti-details">
                                            <span class="noti-title">Lorem info</span> has this
                                            <span class="noti-title">Lorem info</span>
                                        </p>
                                        <p class="noti-time">
                                            <span class="notification-time">4 mins ago</span>
                                        </p>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li class="notification-message">
                            <a href="#">
                                <div class="media d-flex">
                                    <!-- <span class="avatar avatar-sm flex-shrink-0">
                                        <img class="avatar-img rounded-circle" alt="User Image"
                                            src="assets/img/profiles/avatar-11.jpg" />
                                    </span> -->
                                    <div class="media-body flex-grow-1">
                                        <p class="noti-details">
                                            <span class="noti-title">Lorem info</span> has this
                                            <span class="noti-title">Lorem info</span>
                                        </p>
                                        <p class="noti-time">
                                            <span class="notification-time">4 mins ago</span>
                                        </p>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li class="notification-message">
                            <a href="#">
                                <div class="media d-flex">
                                    <!-- <span class="avatar avatar-sm flex-shrink-0">
                                        <img class="avatar-img rounded-circle" alt="User Image"
                                            src="assets/img/profiles/avatar-17.jpg" />
                                    </span> -->
                                    <div class="media-body flex-grow-1">
                                        <p class="noti-details">
                                            <span class="noti-title">Lorem info</span> has this
                                            <span class="noti-title">Lorem info</span>
                                        </p>
                                        <p class="noti-time">
                                            <span class="notification-time">4 mins ago</span>
                                        </p>
                                    </div>
                                </div>
                            </a>
                        </li>

                    </ul>
                </div>
                <div class="topnav-dropdown-footer">
                    <a href="#">View all Notifications</a>
                </div>
            </div>
        </li>

        <li class="nav-item dropdown has-arrow">
            <a href="#" class="dropdown-toggle nav-link" data-bs-toggle="dropdown">
                <span class="user-img"><img class="rounded-circle img-fluid" src="assets/img/1.jpg"
                        width="22" alt="Jassa" /></span>
            </a>
            <div class="dropdown-menu">               
                <a class="dropdown-item">{{displayusername}}</a>
                <a class="dropdown-item" (click)="addnew()">Change Password</a>
                <a class="dropdown-item" (click)="logout()">Logout</a>
            </div>
        </li>
    </ul>
</div>